<!--
 * @Description: 升级管理
 * @Author: kecraft
 * @Date: 2024-03-28 09:32:35
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-29 09:32:18
 * @FilePath: /impact-iotos-console/src/views/upgrade/index.vue
-->
<template>
  <div class="upgrade-container">
    <div>
      <div class="upgrade-box">
        <div class="top-container">
          <div class="tabs-conatiner">
            <div v-for="i in tabs" :key="i" class="tab-item" :class="i !== selectIndex ? 'is_select' : ''"
              @click="handleTabChanged(i)" v-domPreventReClick>
              <div>{{ i }}</div>
              <div class="line" v-if="i === selectIndex"></div>
              <div class="line-none" v-else></div>
            </div>
          </div>
        </div>
        <div class="main-container">
          <firmware v-if="selectIndex === '固件升级'" />
          <software v-if="selectIndex === '软件升级'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import firmware from './firmware';
import software from './software';
import { ref } from 'vue';
const tabs = ref(["固件升级", "软件升级"])
const selectIndex = ref("固件升级");
const handleTabChanged = (v) => {
  if (v === selectIndex.value) return;
  selectIndex.value = v;
}
</script>

<style lang="less" scoped>
.upgrade-container {
  box-sizing: border-box;

  &>div {
    min-height: calc(100vh - 76px);
    background: #FAFAFB;
    box-sizing: border-box;
    padding: 10px;
    padding-top: 0;

    .upgrade-box {
      width: 100%;
      height: calc(100vh - 76px);
      background: #ffffff;
    }
  }
}

.main-container {
  box-sizing: border-box;
  padding: 25px 40px;
}

.tabs-conatiner {
  display: flex;
  box-sizing: border-box;
  padding-top: 14px;
  margin-left: 40px;
  margin-right: 40px;
  border-bottom: 1px solid #EEEEEE;

  .top-container {
    border-bottom: 1px solid #EEEEEE;
  }

  .tab-item {
    text-align: center;
    line-height: 25px;
    box-sizing: border-box;
    margin-right: 30px;
    font-size: 18px;
    cursor: pointer;
  }

  .line {
    margin-top: 12px;
    width: 100%;
    height: 3px;
    background: #367CC5;
  }

}
</style>