<!--
 * @Description: 软件升级
 * @Author: kecraft
 * @Date: 2024-03-28 09:44:33
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-29 09:32:26
 * @FilePath: /impact-iotos-console/src/views/upgrade/software.vue
-->
<template>
  <div class="firmware-upgrade">
    <div class="info-container">
      <p>主要是为了满足用户MCU的版本进行远程升级，让集成了含MCU的终端设备在现网运行过程中能够实现版本的更新迭代和管理功能。</p>
      <p class="mt15">创建任务流程：</p>
      <div class="info-box">
        <span class="info-icon">1</span>
        <span class="info-title">创建并上传升级包</span>
        <span class="line"></span>
        <span class="info-icon">2</span>
        <span class="info-title">选择要升级的设备和策略</span>
        <span class="line"></span>
        <span class="info-icon">3</span>
        <span class="info-title">设备上线开始升级</span>
      </div>
    </div>
    <div class="form-container">
      <div>
        <div class="form-item">
          <span class="form-label">选择产品</span>
          <el-select v-model="form.pk" placeholder="请选择所属产品" class="width200" filterable @change="search">
            <el-option v-for="item in productList" :key="item.pk" :label="item.name" :value="item.pk" />
          </el-select>
        </div>
      </div>
      <div class="btn-normal">
        <div class="btn-green" @click="() => isAdd = true" v-domPreventReClick>
          <img src="@/assets/icon/add.png">
          添加升级包
        </div>
      </div>
    </div>
    <div class="tables">
      <el-table stripe :data="firmwareList" empty-text="暂无数据" v-loading="isload" style="width: 100%;">
        <el-table-column width="80" label="序号" align="center">
          <template #default="scope">
            <span>{{
            scope.$index + 1
          }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="120" label="所属产品" prop="productName" align="center" show-overflow-tooltip />
        <el-table-column min-width="120" label="待升级版本" prop="fromVersion" align="center" show-overflow-tooltip />
        <el-table-column min-width="120" label="升级包版本" prop="toVersion" align="center" />
        <el-table-column min-width="120" label="指定设备" prop="devs" align="center" />
        <el-table-column min-width="120" label="是否启用" prop="enable" align="center">
          <template #default="scope">
            <div class="status-container">
              <el-switch v-model="scope.row.enable" class="ml-2"
                style="--el-switch-on-color: #13ce66;margin-right:10px;" @change="handleSwitchChange(scope.row)" />
              {{ scope.row.enable ? "已启用" : "已禁用" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="220" label="添加时间" prop="createdDate" align="center" />
        <el-table-column width="200" label="操作" align="center">
          <template #default="scope">
            <div class="column-btn">
              <div class="options">
                <span link style="color:#015ee0;cursor: pointer;padding: 0 6px;" @click="handleLook(scope.row)" v-domPreventReClick>
                  下载
                </span>
                <span link style="color:#fc2b2b;cursor: pointer;padding: 0 6px;" @click="handleDel(scope.row)" v-domPreventReClick>
                  删除
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fooner-conatiner">
      <pagination :total="total" @pageChange="getList" class="pagination" />
    </div>
    <upgradeAdd v-if="isAdd" @close="handleClose" type="MCU" />
  </div>
</template>

<script setup>
import pagination from "@/components/control/pagination";
import upgradeAdd from "./upgradeAdd";
import { onMounted, reactive, ref } from 'vue';
import { ElMessage, ElMessageBox } from "element-plus";
import api from "@/api/api";
const isAdd = ref(false);
const productList = ref([]);
const firmwareList = ref([]);
const isload = ref(false);
const total = ref(0)
const form = reactive({
  pk: "",
  page: 0,
  size: 10,
  type: "MCU"
})
const search = () => {
  isload.value = true;
  const { pk, page, size, type } = form
  api.getUpgradeList({
    page, size, type,
    pk: pk === "all" ? "" : pk
  }).then(res => {
    if (res.code === "0") {
      const { content, totalElements } = res.res.data;
      content.forEach(item => {
        if (item.devIdList && item.devIdList.length > 0) {
          item.devs = item.devIdList.join(",")
        } else {
          item.devs = "all"
        }
        productList.value.forEach(product => {
          if (product.pk == item.pk) {
            item.productName = product.name
          }
        })
      })
      total.value = totalElements
      firmwareList.value = content;
      isload.value = false;
    }
  })
}
onMounted(() => {
  api.getAllProducts().then(res => {
    if (res.code === "0") {
      const all = {
        pk: "all",
        name: "全部",
      }
      form.pk = "all"
      productList.value = [all, ...res.res.data];
    }
    search()
  })

})

const handleSwitchChange = (row) => {
  const { id } = row;
  if (!row.enable) {
    // 禁用
    api.disableUpgrade(id).then(res => {
      if (res.code === "0") {
        ElMessage.success("禁用成功！")
      } else {
        firmwareList.value.forEach(item => {
          if (item.id === id) {
            item.enable = true;
          }
        })
      }
    })
  } else {
    // 启用
    api.enableUpgrade(id).then(res => {
      if (res.code === "0") {
        ElMessage.success("启用成功！")
      } else {
        firmwareList.value.forEach(item => {
          if (item.id === id) {
            item.enable = false;
          }
        })
      }
    })
  }
}
const handleLook = (row) => {
  ElMessageBox.confirm("确定下载该升级包吗？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    const { url } = row;
    let eleLink = document.createElement("a");
    eleLink.href = url;
    document.body.appendChild(eleLink);
    eleLink.click();
    document.body.removeChild(eleLink);
  })
}
const handleDel = (row) => {
  ElMessageBox.confirm("确定删除该升级包吗？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    api.delUpgrade(row.id).then(({ code }) => {
      if (code == "0") {
        ElMessage.success("删除成功!")
        search()
      }
    })
  })
}
const getList = (v) => {
  form.page = v.pageNum - 1
  form.size = v.pageSize
  search();
}
const handleClose = (v) => {
  isAdd.value = false;
  if (v) {
    search()
  }
}
</script>


<style lang="less" scoped>
.firmware-upgrade {
  box-sizing: border-box;
}

.btn-green {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  height: 40px;
  border-radius: 4px;
  background: #30AE7D;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: lighter;
  cursor: pointer;

  &>img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
}

.form-item {
  display: flex;
  align-items: center;

  .form-label {
    font-size: 16px;
    color: #606266;
    margin-right: 10px;
  }

  .width200 {
    width: 200px;
  }

}

.info-container {
  font-size: 18px;
  line-height: 25px;
  color: #000000;

  .info-box {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 16px;
    line-height: 22px;

    span {
      display: inline-block;
    }

    .info-icon {
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      border-radius: 50%;
      border: 1px solid #367CC5;
      color: #367CC5;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .info-title {
      margin-right: 15px;
    }

    .line {
      width: 160px;
      height: 1px;
      background: #979797;
      margin-right: 15px;
    }
  }
}

.mt15 {
  margin-top: 15px;
}

.form-container {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.fooner-conatiner {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.tables {
  box-sizing: border-box;
  padding: 20px 0;
}

:deep(.el-table th.el-table__cell) {
  background-color: #F6F7F9;
  height: 50px;
  font-weight: normal;
  color: #000000;
}

:deep(.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell) {
  background-color: #FBFBFB;
}

:deep(.el-table td.el-table__cell div) {
  font-size: 16px;
  line-height: 50px;
}
</style>