<!--
 * @Description: 添加升级包
 * @Author: kecraft
 * @Date: 2024-03-28 10:19:26
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-31 09:27:52
 * @FilePath: /impact-iotos-console/src/views/upgrade/upgradeAdd.vue
-->
<template>
  <div>
    <myDialog @close="handleClose" @ok="handleOk" title="添加升级包">
      <div class="width700">
        <el-form :rules="rules" ref="formUpgradeRef" :model="form" label-position="left">
          <el-form-item label="所属产品：" prop="pk" class="label-feather">
            <el-select v-model="form.pk" placeholder="请选择所属产品" class="width500" filterable>
              <el-option v-for="item in productList" :key="item.pk" :label="item.name" :value="item.pk" />
            </el-select>
          </el-form-item>
          <el-form-item label="目标版本：" prop="toVersion" class="label-feather">
            <el-input v-model="form.toVersion" class="width500" placeholder="请输入目标版本" />
          </el-form-item>
          <el-form-item label="上传升级包：" prop="fileName" class="label-feather">
            <div>
              <div class="upload-top">
                <el-upload action="" class="upload-demo" ref="upload" accept=".zip,.bin,.rar" :limit="1" :show-file-list="false"
                  :on-exceed="handleExceed" :http-request="changeUpload">
                  <template #trigger>
                    <div class="btn-upload" v-domPreventReClick>
                      <img src="@/assets/menu/upload.png">点击上传
                    </div>
                  </template>
                </el-upload>
                <div>支持rar、bin、zip格式，文件大小小于50M</div>
              </div>
              <div v-if="isUploading" class="file-container">
                <img src="@/assets/drive/loading.png">
                上传{{ fileUploadPercent }}%...
              </div>
              <div v-if="!isUploading && form.fileName" class="file-container">
                <img src="@/assets/drive/success.png">
                上传成功
              </div>
              <div class="file-container" v-if="!isUploading && form.fileName">
                <img src="@/assets/drive/file.png">
                <div>{{ form.fileName }}</div>
                <img src="@/assets/drive/close.png" class="is-close" @click="handleClear" v-domPreventReClick>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="待升级版本：" prop="fromAnyVersion" class="label-feather">
            <el-select v-model="form.fromAnyVersion" placeholder="请选择" class="width500">
              <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="版本号：" prop="fromVersion" v-if="form.fromAnyVersion === false" class="label-feather">
            <el-input v-model="form.fromVersion" class="width500" placeholder="请输入指定版本号" />
          </el-form-item>
          <el-form-item label="待升级设备：" class="label-feather">
            <div class="device-container">
              <el-radio-group v-model="form.upgradeAll" @change="handleUpgradeChange">
                <el-radio :label="true">全部设备</el-radio>
                <el-radio :label="false">指定设备</el-radio>
              </el-radio-group>
              <div class="top-box" v-if="!form.upgradeAll">
                <el-input v-model="form.deviceId" style="width: 100%;" placeholder="请输入设备ID"
                  @keyup.enter="handleDeviceAdd" />
              </div>
              <div class="bottom-box" v-if="!form.upgradeAll">
                <el-scrollbar style="height: 100%" wrap-style="overflow-x:hidden;">
                  <div v-for="(item, index) of deviceList" :key="index" class="device-item">
                    <img src="@/assets/icon/del-upgrade.png" @click="del(index)" v-domPreventReClick>
                    <div>{{ item }}</div>
                  </div>
                </el-scrollbar>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </myDialog>
  </div>
</template>

<script setup>
import myDialog from "@/components/dialog/myDialog.vue";
import { ElMessage } from "element-plus";
import { onMounted, reactive, ref, defineEmits, defineProps, getCurrentInstance } from "vue";
import api from "@/api/api"
const emit = defineEmits(["close"]);
const props = defineProps(['type']);
const productList = ref([]);
const deviceList = ref([]);
const typeList = ref([
  {
    label: "指定版本",
    value: false,
  },
  {
    label: "全部版本",
    value: true,
  }
])
const form = reactive({
  pk: "", // 所属产品
  upgradeType: "", // 升级类型
  toVersion: "", // 目标版本
  fileName: "", // 文件名称
  md5: "", // 文件md5
  url: "", // 文件url
  fromAnyVersion: "", // 待升级版本
  fromVersion: "", // 指定待升级版本
  upgradeAll: true,
  deviceId: "",
})
const rules = {
  pk: [
    { required: true, message: "所属产品不能为空", trigger: "change" }
  ],
  toVersion: [
    { required: true, message: "目标版本不能为空", trigger: "change" }
  ],
  fileName: [
    { required: true, message: "上传升级包不能为空", trigger: "change" }
  ],
  fromAnyVersion: [
    { required: true, message: "待升级版本不能为空", trigger: "change" }
  ],
}
const fileUploadPercent = ref(0);
const isUploading = ref(false);
let currentInstance = "";
onMounted(() => {
  currentInstance = getCurrentInstance();
  const type = props.type;
  form.upgradeType = type;
  api.getAllProducts().then(res => {
    if (res.code === "0") {
      productList.value = res.res.data;
    }
  })
})
const handleClose = () => {
  emit("close")
}
const formUpgradeRef = ref(null);
const handleOk = () => {
  formUpgradeRef.value.validate((valid) => {
    if (valid) {
      if (form.upgradeAll === true) {
        //
      } else if (form.upgradeAll === false) {
        // 指定设备 
        if (deviceList.value.length === 0) {
          ElMessage.error("请至少指定一个设备！")
          return false;
        }
      } else {
        ElMessage.error("请至少指定一个设备！")
        return false;
      }
      const { pk, upgradeType, toVersion, md5, url, fromAnyVersion, fromVersion, upgradeAll } = form;
      api.createUpgrade({
        pk,
        upgradeType,
        toVersion,
        md5,
        url,
        fromAnyVersion,
        fromVersion: fromAnyVersion ? "" : fromVersion, // 如果是指定版本才需要填
        upgradeAll,
        devIdList: upgradeAll ? [] : deviceList.value, // 如果是指定设备才需要填
      }).then(res => {
        if (res.code === "0") {
          ElMessage.success("创建成功！")
          emit("close", true)
        }
      })
    } else {
      return false;
    }
  });
}
const changeUpload = (e) => {
  const file = e.file; // 文件信息
  let fd = new FormData();
  fd.append("file", file);
  isUploading.value = true;
  api.upgradeAddFile(fd, onDownloadProgress).then(res => {
    if (res.code === "0") {
      const { filename, md5, url } = res.res.data;
      form.fileName = filename;
      form.md5 = md5;
      form.url = url;
      fileUploadPercent.value = 100;
      setTimeout(() => {
        isUploading.value = false;
      }, 500);
      ElMessage.success("上传成功!")
    }
  })

}
const handleClear = () => {
  form.fileName = "";
  form.filePath = "";
  form.fileUrl = "";
}
const onDownloadProgress = (progressEvent) => {
  let complete = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(
    0
  );
  fileUploadPercent.value = parseInt(complete) < 99 ? parseInt(complete) : 99;
};
const handleDeviceAdd = () => {
  if (!form.deviceId) return;
  deviceList.value.push(form.deviceId);
  form.deviceId = "";
}

const del = (v) => {
  deviceList.value.splice(v, 1)
}

const handleUpgradeChange = () => {
  if (form.upgradeAll) {
    deviceList.value = []
  }
}

const handleExceed = (e) => {
  currentInstance.proxy.$refs.upload.clearFiles();
  currentInstance.proxy.$refs.upload.handleStart(e[0]);
  currentInstance.proxy.$refs.upload.submit();
  form.fileName = ""
  form.md5 = ""
  form.url = ""
}
</script>

<style lang="less" scoped>
.file-container {
  display: flex;
  align-items: center;
  margin-top: 10px;

  img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  .is-close {
    margin-left: 20px;
    width: 14px;
    height: 14px;
  }
}

.upload-top {
  display: flex;
  align-items: center;
  color: #919191;
}

.btn-upload {
  margin-right: 20px;
  width: 136px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #B7BBC0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 15px;
    height: 15px;
    margin-right: 4px;
  }
}

.top-box {
  margin-top: 17px;
}

.width700 {
  width: 700px;
}

.width500 {
  width: 500px;
}

.add-title {
  font-weight: bold;
  margin-top: 40px;
}

.device-container {
  width: 500px;
  box-sizing: border-box;
  border: 1px dashed #E9ECF0;
  background: #F9FCFD;
  margin-top: 10px;
  padding: 20px;
  border-radius: 4px;
}

.device-item {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  line-height: 20px;
  margin-top: 10px;
  color: #333333;

  img {
    width: 14px;
    height: 14px;
    margin-right: 20px;
  }
}

.btn-groups :deep(.el-button) {
  font-size: 14px;
  height: 36px;
  background: #015ee0;
}

.btn-groups :deep(.el-button:hover) {
  font-size: 14px;
  height: 36px;
  background: #337ecc;
}


.btn-groups :deep(.el-button:active) {
  font-size: 14px;
  height: 36px;
  background: #337ecc;
}

.bottom-box {
  height: 140px;
}
</style>

<style lang="less">
.label-feather {
  .el-form-item__label {
    width: 180px;
  }
}
</style>